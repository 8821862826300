<template>
  <div class="homePage">
    <!-- 轮播大图 -->
    <div class="ziHead" :style="{ height: scrollerHeight }">
      <div class="swiper-container" id="bigPhoto" :style="{ height: scrollerHeight }">
        <div class="swiper-wrapper" :style="{ height: scrollerHeight }">
          <div
            class="swiper-slide"
            data-swiper-autoplay="3000"
            v-for="(item, index) in imgList"
            :key="index"
            :style="{ height: scrollerHeight }"
          >
            <a
              v-if="item.urlType == 1 && item.fileName && item.fileName !== ''"
              :href="item.url"
              target="_blank"
              :title="item.title"
              :style="{ height: scrollerHeight }"
            >
              <img class="swiper_img" :src="item.fileName" style="width: 100%; height: 100%" />
            </a>
            <a
              v-if="item.urlType == 2 && item.fileName && item.fileName !== ''"
              :href="`/learn/${item.url}`"
              target="_blank"
              :title="item.title"
            >
              <img class="swiper_img" :src="item.fileName" style="width: 100%; height: 100%" />
            </a>
            <a
              v-if="item.urlType == 3 && item.fileName && item.fileName !== ''"
              :href="`/detail/${null}/${item.url}/${item.title}`"
              target="_blank"
              :title="item.title"
            >
              <img class="swiper_img" :src="item.fileName" style="width: 100%; height: 100%" />
            </a>
            <a v-if="item.urlType == 4 && item.fileName && item.fileName !== ''" :title="item.title">
              <img class="swiper_img" :src="item.fileName" style="width: 100%; height: 100%" />
            </a>
          </div>
          <!-- <div class="swiper-slide" data-swiper-autoplay="3000"><img  class="swiper_img" src="../../assets/image/homepage2.jpg" style="width: 100% ; height: 100% ;"></div> -->
        </div>
        <div class="swiper-button-prev swiper-button-prev-banner"></div>
        <div class="swiper-button-next swiper-button-next-banner"></div>
      </div>
    </div>
    <!-- 主体 -->
    <div class="homeMain">
      <!-- 头条新闻部分 -->
      <div class="main_panel">
        <div class="Notice">
          <div class="Tit"><img src="../../assets/image/NewhotTit.jpg" /></div>
          <div class="r">
            <div class="news swiper-container" id="news">
              <div class="news swiper-wrapper">
                <div class="news swiper-slide" v-for="(item, index) in newsTextList" :key="index">
                  <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                    <h2>{{ item.title }}</h2>
                    <p class="homeText">{{ item.artDesc | ellipsis }} <span>[查看详情]</span></p>
                  </a>
                  <router-link v-else target="_blank" :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <h2>{{ item.title }}</h2>
                    <p class="homeText">{{ item.artDesc | ellipsis }} <span>[查看详情]</span></p>
                  </router-link>
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination-news"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 间隔 -->
      <div class="clear"></div>
      <!-- 新闻动态部分 -->
      <div class="main_panel">
        <!-- 新闻列表图片轮播图 -->
        <div class="NewsL">
          <div class="Tit">
            <div class="en">NEWS</div>
            <div class="cn">新闻动态</div>
          </div>
          <!-- 新闻列表轮播图 -->
          <div id="newsFlashItem" style="width: 100%; overflow: hidden">
            <div class="flashImg" style="display: block">
              <div v-show="catalogActive == 0" class="flashImg-container swiper-container" id="newsFlash01">
                <div class="flashImg-wrapper swiper-wrapper">
                  <div class="flashImg-slide swiper-slide" v-for="(item, index) in newsFlashList01" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-button-prev flashImg-prev"></div>
                <div class="swiper-button-next flashImg-next"></div>
              </div>
              <div v-show="catalogActive == 1" class="flashImg-container swiper-container" id="newsFlash02">
                <div class="flashImg-wrapper swiper-wrapper">
                  <div class="flashImg-slide swiper-slide" v-for="(item, index) in newsFlashList02" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-button-prev flashImg-prev"></div>
                <div class="swiper-button-next flashImg-next"></div>
              </div>
              <div v-show="catalogActive == 2" class="flashImg-container swiper-container" id="newsFlash03">
                <div class="flashImg-wrapper swiper-wrapper">
                  <div class="flashImg-slide swiper-slide" v-for="(item, index) in newsFlashList03" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-button-prev flashImg-prev"></div>
                <div class="swiper-button-next flashImg-next"></div>
              </div>
              <div v-show="catalogActive == 3" class="flashImg-container swiper-container" id="newsFlash04">
                <div class="flashImg-wrapper swiper-wrapper">
                  <div class="flashImg-slide swiper-slide" v-for="(item, index) in newsFlashList04" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-button-prev flashImg-prev"></div>
                <div class="swiper-button-next flashImg-next"></div>
              </div>
              <div v-show="catalogActive == 4" class="flashImg-container swiper-container" id="newsFlash05">
                <div class="flashImg-wrapper swiper-wrapper">
                  <div class="flashImg-slide swiper-slide" v-for="(item, index) in newsFlashList05" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" target="_blank">
                      <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                      <div class="txt">
                        <p class="txtTitle">{{ item.title }}</p>
                        <p class="txtContent">
                          {{ item.artDesc | capitalize }}
                          <span style="color: #0162b3; font-size: 15px; font-weight: 1000">[查看详情]</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-button-prev flashImg-prev"></div>
                <div class="swiper-button-next flashImg-next"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 新闻内容列表 -->
        <div class="NewsR">
          <div class="Tit" id="NewsT">
            <div
              class="NewsTInner"
              :class="{ active: catalogActive == index }"
              v-for="(item, index) in catalogList"
              :key="index"
              @mouseenter="mouseenterCatalog(index)"
            >
              <a :href="item.url">{{ item.title }}</a>
            </div>
          </div>

          <div class="ItemList" id="NewsItem">
            <div class="NewsItem">
              <ul class="comUl" v-if="catalogActive == 0">
                <li v-for="(item, index) in newCatalogList01" :key="index">
                  <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">{{ item.title }}</a>
                  <router-link v-else target="_blank" :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{
                    item.title
                  }}</router-link>
                  <span>{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                </li>
              </ul>
              <ul class="comUl" v-if="catalogActive == 1">
                <li v-for="(item, index) in newCatalogList02" :key="index">
                  <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">{{ item.title }}</a>
                  <router-link v-else target="_blank" :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{
                    item.title
                  }}</router-link>
                  <span>{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                </li>
              </ul>
              <ul class="comUl" v-if="catalogActive == 2">
                <li v-for="(item, index) in newCatalogList03" :key="index">
                  <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">{{ item.title }}</a>
                  <router-link v-else target="_blank" :to="`/detail/00190007/${item.entTArticleUuid}`">{{
                    item.title
                  }}</router-link>
                  <span>{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                </li>
              </ul>
              <ul class="comUl" v-if="catalogActive == 3">
                <li v-for="(item, index) in newCatalogList04" :key="index">
                  <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">{{ item.title }}</a>
                  <router-link v-else target="_blank" :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{
                    item.title
                  }}</router-link>
                  <span>{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                </li>
              </ul>
              <ul class="comUl" v-if="catalogActive == 4">
                <li v-for="(item, index) in newCatalogList05" :key="index">
                  <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">{{ item.title }}</a>
                  <router-link v-else target="_blank" :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{
                    item.title
                  }}</router-link>
                  <span>{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 重要公告部分 -->
      <div class="main_panel">
        <div class="importNoticeImg1">
          <a href="/pressCenter/00190011" target="_blank">
            <img :src="midbannerList" style="width: 100%" />
          </a>
        </div>
        <div class="importNotice">
          <div class="t"><span>重要公告：</span></div>
          <div class="Gd">
            <div class="swiperNotice swiper-container_2" id="swiperNotice">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in newsNoticeList" :key="index">
                  <a
                    :href="
                      item.url && item.url !== '' ? item.url : `/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`
                    "
                    target="_blank"
                  >
                    <span>{{ item.title }}</span>
                    <span class="time">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="/notice/cbf93715-1547-4f5b-ae0d-df230985a238"><div class="fakeEnterprise"></div></a>
        <!-- notice -->
        <!-- <a href="/learn/0167" target="_blank" class="importNoticeImg">
          <img src="../../assets/image/xdswcx.jpg" />
        </a> -->
      </div>
    </div>
    <!-- 业务板块 甘肃建投 中国500强企业   -->
    <div class="width_100 importYeWu">
      <div class="width_100 importYeWuTit text-center" style="margin: 37px 0px; height: 60px; overflow: hidden">
        <img src="../../assets/image/500q_11.png" />
      </div>
      <div class="main_panel Item">
        <div class="Left" id="YwLeft">
          <ul class="YwLeftPc">
            <li
              id="YwLeftul"
              :class="{ active: active == index }"
              v-for="(item, index) in ywList"
              :key="index"
              @mouseover="mouseChange(item, index)"
            >
              <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">{{ item.name }}</a>
              <router-link
                v-if="item.entTChannelUuid !== '00200006'"
                target="_blank"
                :to="`/businessUnit/${item.entTChannelUuid}`"
                >{{ item.name }}</router-link
              >
              <router-link v-else target="_blank" :to="`/businessUnit/technology/00200006`">{{
                item.name
              }}</router-link>
              <img src="../../assets/image/importYeWu_li.png" :class="{ active: active == index }" />
            </li>
          </ul>
          <ul class="YwLeftMobile">
            <li
              id="YwLeftul"
              :class="{ active: active == index }"
              v-for="(item, index) in ywList"
              :key="index"
              @mouseover="mouseChangeMobile(item, index)"
            >
              <a v-if="item.url && item.url !== ''" target="_blank">{{ item.name }}</a>
              <a v-else>{{ item.name }}</a>
              <img src="../../assets/image/importYeWu_li.png" :class="{ active: active == index }" />
            </li>
          </ul>
        </div>
        <div class="Right" id="YwRight">
          <div class="Rlist">
            <a :href="indexUrl" target="_blank">
              <img :src="indexImg" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 各种链接，网站集群 -->
    <div class="width_100 Links">
      <div class="main_panel">
        <el-row :gutter="15">
          <el-col :span="8" :xs="24">
            <div class="P1">
              <a href="/report" target="_blank">
                <div class="ct">甘肃建投纪检监察举报中心</div>
                <div class="linkInner">
                  <p>
                    举报网址：http://www.gsjtw.cc/report <br />
                    举报电话：0931-2216235<br />
                    举报地址：兰州市七里河区西津东路575号甘肃建投纪委
                  </p>
                </div>
              </a>
            </div>
            <div class="linkBottom">
              <a href="http://zhaopin.gsjtw.cc" target="_blank">
                <span class="txt">人才招聘</span>
                <span>Recruid</span>
              </a>
            </div>
          </el-col>
          <el-col :span="8" :xs="24">
            <div class="P1 linkPetition">
              <a href="/petition" target="_blank">
                <div class="ct">甘肃建投网上信访信息中心</div>
                <div class="linkInner">
                  <p>
                    投诉网址：http://www.gsjtw.cc/petition <br />
                    信访电话：0931-2790689 2216527<br />
                    传真：2790689<br />
                    信访地址：兰州市七里河区西津东路575号甘肃建投群众工作部信访维稳办公室
                  </p>
                </div>
              </a>
            </div>
            <div class="linkBottom">
              <a href="http://www.jtdzpt.com/" target="_blank">
                <span class="txt">采购频道</span>
                <span>Purchase</span>
              </a>
            </div>
          </el-col>
          <el-col :span="8" :xs="24">
            <div class="P3">
              <div class="t">
                <div class="tInner">
                  <span>集<br />团<br />站<br />群<br /></span>
                  <div class="en">LINK</div>
                </div>
              </div>
              <div class="nr">
                <div class="link-container swiper-container" id="link">
                  <div class="link-wrapper swiper-wrapper">
                    <div
                      class="link-slide swiper-slide"
                      v-for="(item, index) in newtitleList"
                      :key="index"
                      style="height: 30px"
                    >
                      <a :href="item.httpUrl" target="_blank" class="aItem">{{ item.name }} </a>
                    </div>
                  </div>
                  <div class="swiper-scrollbar"></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- <div class="P3">
          <div class="t">集<br />团<br />站<br />群<br /></div>
          <div class="en">LINK</div>
          <div class="nr">
            <div class="link-container swiper-container" id="link">
              <div class="link-wrapper swiper-wrapper">
                <div
                  class="link-slide swiper-slide"
                  v-for="(item, index) in newtitleList"
                  :key="index"
                  style="height: 30px ;"
                >
                  <a :href="item.httpUrl" target="_blank" class="aItem">{{ item.name }} </a>
                </div>
              </div>
              <div class="swiper-scrollbar"></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 间隔 -->
    <div class="clear"></div>
    <!-- 精品项目轮播图 -->
    <div class="Jpgcw width_100">
      <div class="main_panel Pcn">
        <a class="JpgcwTitle" href="" style="">精品项目</a>
      </div>
      <div class="Pen">Quality Project</div>
      <div class="main_panel" id="Jpgc">
        <div class="JpgcSwiper-container swiper-container" id="jpgcPhoto">
          <div class="JpgcSwiper-wrapper swiper-wrapper">
            <div class="JpgcSwiper-slide swiper-slide" v-for="(item, index) in JpgcList" :key="index">
              <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank" class="JpItem">
                <div class="img">
                  <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                </div>
                <div class="Tit">{{ item.title }}</div>
                <div class="nr"></div>
              </a>
              <a
                v-else
                :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                target="_blank"
                class="JpItem"
              >
                <div class="img">
                  <img class="swiper_img" :src="item.thumb1" style="width: 100%; height: 100%" />
                </div>
                <div class="Tit">{{ item.title }}</div>
                <div class="nr"></div>
              </a>
            </div>
          </div>
          <div class="swiper-button-prev slideButtonPrev"></div>
          <div class="swiper-button-next slideButtonNext"></div>
        </div>
      </div>
      <a href="/learn/0086" target="_blank" class="jpmore">查看更多</a>
    </div>

    <!-- 飘窗 -->
    <window-box ref="windowBox"></window-box>
  </div>
</template>

<script>
import Swiper from 'swiper'
import {
  articles,
  getTopArtList,
  getChildChannel,
  getAdvert,
  picArticles,
  getApplicationList,
  picArticlesRecommend
} from '@/api/common'
import windowBox from '@/components/windowBox/index.vue'
export default {
  components: {
    windowBox
    // VueSeamless,
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      active: 0,
      ismouseover: true,
      indexImg: '',
      indexUrl: '',
      imgUrl: '/pressCenter/0019001',
      // 头条新闻轮播内容
      newsTextList: [],
      //轮播背景大图
      imgList: [],
      // 中间banner
      midbannerList: '',
      //业务板块内容
      ywList: [],
      //精品工程轮播图
      JpgcList: [],
      //新闻列表轮播图
      flashImgList: [],
      //新闻目录
      catalogActive: 0,
      catalogList: [
        {
          id: 1,
          url: '/pressCenter/00190001',
          title: '集团要闻'
        },
        {
          id: 2,
          url: '/pressCenter/00190005',
          title: '媒体聚焦'
        },
        {
          id: 3,
          url: '/pressCenter/radioReport/00240001',
          title: '电视新闻'
        },
        {
          id: 4,
          url: '/pressCenter/00190003',
          title: '深度报道'
        },
        {
          id: 5,
          url: '/pressCenter/00190004',
          title: '基层报道'
        }
      ],
      //新闻列表
      newtitleList: [],
      newCatalogList01: [],
      newCatalogList02: [],
      newCatalogList03: [],
      newCatalogList04: [],
      newCatalogList05: [],
      newsNoticeList: [],
      newsFlashList01: [],
      newsFlashList02: [],
      newsFlashList03: [],
      newsFlashList04: [],
      newsFlashList05: []
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 100) {
        return value.slice(0, 100) + '...'
      }
      return value
    },
    capitalize: function(value) {
      if (!value) return ''
      if (value.length > 40) {
        return value.slice(0, 40) + '...'
      }
      return value
    }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function() {
          // 打印screenWidth变化的值
          console.log(that.screenWidth)
          that.timer = false
        }, 400)
      }
    }
  },
  computed: {
    scrollerHeight() {
      return this.screenWidth / 3.52 + 'px'
    },
    classOption() {
      return {
        swiper: '',
        step: 0.8,
        direction: 2, // 0向下 1向上 2向左 3向右
        // limitMoveNum: this.newsNoticeList.length,// 开始无缝滚动的数据量 this.dataList.length
        limitMoveNum: 10,
        hoverStop: true,
        openTouch: false,
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 0 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },

  mounted() {
    var _this = this

    this.midbanner()
    this.bannerImg()
    this.bannerNews()
    this.newsNotice()
    this.bannerJpgc()
    // this.bannernewsFlash()
    this.bannerlink()

    _this.picArticles()
    _this.newsCatalog()
    _this.businessInit()

    // const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        _this.screenWidth = window.screenWidth
      })()
    }
  },
  methods: {
    // 查询焦点图
    picArticlesRecommend() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '',
        pageNum: 1,
        pageSize: 6
      }
      picArticlesRecommend(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newtitleList = data
        }
      })
    },
    // 站点
    getApplicationList() {
      var _this = this

      getApplicationList().then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newtitleList = data
        }
      })
    },
    // 业务板块
    businessInit() {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: '0020'
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        // if (code === 1 && data) {
        //   _this.ywList = data
        //   _this.indexImg = data[0].fileName
        //   if (data[0].url && data[0].url !== '') {
        //     _this.indexUrl = data[0].url
        //   }
        //   else {
        //     if( data.entTChannelUuid == "00200006"){
        //       _this.indexUrl = "/businessUnit/technology/00200006"
        //     }
        //     else{
        //       _this.indexUrl = `/businessUnit/${data[0].entTChannelUuid}`
        //     }
        //   }
        // }
        // data.forEach(line => {
        //       if (line.fileName && line.fileName !== '') {
        //         _this.imgList.push(line)
        //       }
        //     })

        if (code === 1 && data) {
          _this.ywList = data
          _this.indexImg = data[0].fileName
          data.length &&
            data.forEach(line => {
              if (line.url && line.url !== '') {
                _this.indexUrl = line.url
                _this.indexImg = line.fileName
              } else {
                if (line.entTChannelUuid == '00200006') {
                  _this.indexUrl = `/businessUnit/technology/00200006`
                  _this.indexImg = line.fileName
                } else {
                  _this.indexUrl = `/businessUnit/${line.entTChannelUuid}`
                  _this.indexImg = line.fileName
                }
              }
            })
        }
      })
    },
    // 焦点图
    picArticles() {
      var _this = this

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190001',
        pageNum: 1,
        pageSize: 5
      }
      picArticlesRecommend(data01).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsFlashList01 = data.rows
          this.$nextTick(() => {
            new Swiper('#newsFlash01', {
              autoplay: 4000,
              speed: 300,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              prevButton: '.flashImg-prev',
              nextButton: '.flashImg-next'
              // loop: true,
              // autoplay: {
              //   delay: 4000,
              //   disableOnInteraction: false //1秒切换一次
              // },
              // navigation: {
              //   nextEl: '.flashImg-next',
              //   prevEl: '.flashImg-prev'
              // },
              // observer: true,
              // observeParents: true,
              // observeSlideChildren: true
            })
          })
        }
      })

      let data02 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190005',
        pageNum: 1,
        pageSize: 10
      }
      picArticlesRecommend(data02).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsFlashList02 = data.rows
          this.$nextTick(() => {
            new Swiper('#newsFlash02', {
              autoplay: 4000,
              speed: 300,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              prevButton: '.flashImg-prev',
              nextButton: '.flashImg-next'
            })
          })
        }
      })

      let data03 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00240001',
        pageNum: 1,
        pageSize: 10
      }
      picArticlesRecommend(data03).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsFlashList03 = data.rows
          this.$nextTick(() => {
            new Swiper('#newsFlash03', {
              autoplay: 4000,
              speed: 300,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              prevButton: '.flashImg-prev',
              nextButton: '.flashImg-next'
            })
          })
        }
      })

      let data04 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190003',
        pageNum: 1,
        pageSize: 10
      }
      picArticlesRecommend(data04).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsFlashList04 = data.rows
          this.$nextTick(() => {
            new Swiper('#newsFlash04', {
              autoplay: 4000,
              speed: 300,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              prevButton: '.flashImg-prev',
              nextButton: '.flashImg-next'
            })
          })
        }
      })

      let data05 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190004',
        pageNum: 1,
        pageSize: 10
      }
      picArticlesRecommend(data05).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsFlashList05 = data.rows
          this.$nextTick(() => {
            new Swiper('#newsFlash05', {
              autoplay: 4000,
              speed: 300,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              prevButton: '.flashImg-prev',
              nextButton: '.flashImg-next'
            })
          })
        }
      })
    },
    // 新闻动态-右
    newsCatalog() {
      var _this = this

      // 集团要闻
      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190001',
        pageNum: 1,
        pageSize: 10
      }
      articles(data01).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList01 = data.rows
        }
      })

      // 媒体聚焦
      let data02 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190005',
        pageNum: 1,
        pageSize: 10
      }
      articles(data02).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList02 = data.rows
        }
      })

      // 电视新闻
      let data03 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00240001',
        pageNum: 1,
        pageSize: 10
      }
      articles(data03).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList03 = data.rows
        }
      })

      // 深度报道
      let data04 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190003',
        pageNum: 1,
        pageSize: 10
      }
      articles(data04).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList04 = data.rows
        }
      })

      // 基层报道
      let data05 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190004',
        pageNum: 1,
        pageSize: 10
      }
      articles(data05).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList05 = data.rows
        }
      })
    },
    // banner
    bannerImg() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 1
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length &&
            data.forEach(line => {
              if (line.fileName && line.fileName !== '') {
                _this.imgList.push(line)
              }
            })
          this.$nextTick(() => {
            new Swiper('#bigPhoto', {
              autoplay: 4000,
              speed: 300,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              prevButton: '.swiper-button-next-banner',
              nextButton: '.swiper-button-prev-banner'
              // loop: true,
              // autoplay: {
              //   disableOnInteraction: false
              // },
              // navigation: {
              //   nextEl: '.swiper-button-next-banner',
              //   prevEl: '.swiper-button-prev-banner'
              // },
              // observer: true,
              // observeParents: true,
              // observeSlideChildren: true
            })
          })
        }
      })
    },
    //新闻轮播
    bannerNews() {
      var _this = this
      let data = {
        applicationUuid: _this.webId
      }
      getTopArtList(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsTextList = data
          this.$nextTick(() => {
            new Swiper('#news', {
              autoplay: 2000,
              speed: 500,
              loop: true,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false, //用户操作swiper之后重新启动autoplay
              pagination: '.swiper-pagination-news',
              paginationClickable: true
              // loop: true,
              // pagination: {
              //   el: '.swiper-pagination-news', //分页器
              //   clickable: true
              // },
              // autoplay: {
              //   disableOnInteraction: false
              // },
              // observer: true,
              // observeParents: true,
              // observeSlideChildren: true
            })
          })
        }
      })
    },
    // 通知公告
    newsNotice() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '00190009',
        pageNum: 1,
        pageSize: 10
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsNoticeList = data.rows
          this.$nextTick(() => {
            this.swiper = new Swiper('#swiperNotice', {
              autoplay: 1,
              loop: true,
              speed: 10000,
              delay: 0,
              slidesPerView: 'auto',
              spaceBetween: 45,
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
              autoplayDisableOnInteraction: false
            })

            // mySwiper.el.onmouseover = function() {
            //   mySwiper.autoplay.stop()
            // }
            // mySwiper.el.onmouseout = function() {
            //   mySwiper.autoplay.start()
            // }

            // 存放鼠标悬浮时的transform属性（行内属性）
            let nextTransForm = ''
            // 轮播图从暂停位置移动到原本应到的位置所用的时间
            let nextTime = 0

            // 鼠标悬浮暂停轮播
            this.swiper.container[0].onmouseover = function() {
              nextTransForm = document
                .getElementsByClassName('swiper-container_2')[0]
                .getElementsByClassName('swiper-wrapper')[0].style.transform
              // 轮播图原本应移动到的位置
              let nextTransPosition =
                -1 *
                parseInt(
                  document
                    .getElementsByClassName('swiper-container_2')[0]
                    .getElementsByClassName('swiper-wrapper')[0]
                    .style.transform.split('translate3d(')[1]
                    .split('px')[0]
                )

              // 鼠标悬浮时时轮播图位置
              let nowTransPosition =
                -1 *
                parseInt(
                  window
                    .getComputedStyle(
                      document
                        .getElementsByClassName('swiper-container_2')[0]
                        .getElementsByClassName('swiper-wrapper')[0],
                      false
                    )
                    ['transform'].split('1, ')[2]
                    .split(',')[0]
                )
              // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
              let nowTransForm = window.getComputedStyle(
                document.getElementsByClassName('swiper-container_2')[0].getElementsByClassName('swiper-wrapper')[0],
                false
              )['transform']
              // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（370是我自定义的每个slide的宽度）
              nextTime = 5500 * ((nextTransPosition - nowTransPosition) / 370)
              // 改变行内transform属性
              document
                .getElementsByClassName('swiper-container_2')[0]
                .getElementsByClassName('swiper-wrapper')[0].style.transform = nowTransForm
              // 不写也没关系
              document
                .getElementsByClassName('swiper-container_2')[0]
                .getElementsByClassName('swiper-wrapper')[0].style.transitionDuration = '0ms'
              this.swiper.stopAutoplay()
            }
            // 鼠标离开轮播图开始轮播
            this.swiper.container[0].onmouseout = function() {
              // console.log('leave' + nextTransForm)
              // 恢复原样
              document
                .getElementsByClassName('swiper-container_2')[0]
                .getElementsByClassName('swiper-wrapper')[0].style.transform = nextTransForm
              document
                .getElementsByClassName('swiper-container_2')[0]
                .getElementsByClassName('swiper-wrapper')[0].style.transitionDuration = nextTime + 'ms'
              this.swiper.startAutoplay()
            }
          })
        }
      })
    },
    // 中间banner
    midbanner() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 3
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.midbannerList = data[0].fileName)
        }
      })
    },
    //精品工程轮播图
    bannerJpgc() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '0086',
        pageNum: 1,
        pageSize: 10
      }
      picArticles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList = data.rows
          this.$nextTick(() => {
            new Swiper('#jpgcPhoto', {
              loop: true,
              speed: 2000,
              autoplay: 1000,
              updateOnImagesReady: true,
              slidesPerView: 3,
              spaceBetween: 20,
              prevButton: '.slideButtonNext',
              nextButton: '.slideButtonPrev',
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
              autoplayDisableOnInteraction: false,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 10
                }
              }
            })
          })
        }
      })
    },

    //网站链接轮播
    bannerlink() {
      var _this = this

      getApplicationList().then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newtitleList = data
          this.$nextTick(() => {
            var mySwiper = new Swiper('#link', {
              direction: 'vertical',
              autoHeight: true,
              speed: 3000,
              autoplay: 1,
              delay: 0,
              loop: true,
              // grabCursor: true, //手型
              slidesPerView: 6,
              spaceBetween: 0,
              scrollbar: '.swiper-scrollbar',
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
              autoplayDisableOnInteraction: false
            })

            mySwiper.container[0].onmouseover = function() {
              mySwiper.stopAutoplay()
            }
            mySwiper.container[0].onmouseout = function() {
              mySwiper.startAutoplay()
            }
          })
        }
      })
    },
    mouseChange(val, index) {
      this.active = index
      this.indexImg = val.fileName
      if (val.url && val.url !== '') {
        this.indexUrl = val.url
      } else {
        if (val.entTChannelUuid == '00200006') {
          this.indexUrl = `/businessUnit/technology/00200006`
          this.indexImg = val.fileName
        } else {
          this.indexUrl = `/businessUnit/${val.entTChannelUuid}`
          this.indexImg = val.fileName
        }
      }
    },

    mouseChangeMobile(val, index) {
      this.active = index
      this.indexImg = val.fileName
    },
    mouseenterCatalog(val) {
      var _this = this

      _this.catalogActive = val
    }
  }
}
</script>

<style lang="scss">
@import './style.scss';
.homePage {
  width: 100%;
  height: auto;
  overflow: hidden;
}
// 轮播背景大图
.homePage .ziHead {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.homePage .ziHead .swiper-container {
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      text-align: center;
      position: relative;
      overflow: hidden;
      // height: 540px;
      height: auto;
    }
  }
}
.homePage .ziHead .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url(../../assets/image/ibg2.png);
  background-size: cover;
  position: absolute;
  left: 6%;
  top: 45%;
  margin-top: -15px;
  width: 65px;
  height: 65px;
  z-index: 89;
  opacity: 0.5;
}
.homePage .ziHead .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url(../../assets/image/ibg2a.png);
  background-size: cover;
  position: absolute;
  right: 6%;
  top: 45%;
  margin-top: -15px;
  width: 65px;
  height: 65px;
  z-index: 89;
  opacity: 0.5;
}
.homePage .homeMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 30px;
}
.homePage .homeMain .main_panel {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

// 头条新闻部分
.homePage .homeMain .Notice {
  height: auto;
  overflow: hidden;
  line-height: 24px;
}
.homePage .homeMain .Notice .Tit {
  width: 126px;
  height: 151px;
  overflow: hidden;
  float: left;
}
.homePage .homeMain .Notice .Tit img {
  border: 0px;
  vertical-align: middle;
}
.homePage .homeMain .Notice .r {
  width: 1023px;
  float: right;
  overflow: hidden;
}

.homePage .homeMain .Notice .r h2 {
  color: #000000;
  font-weight: bold;
  text-align: center;
  // background-color: antiquewhite;
  line-height: 42px;
  font-size: 34px;
  margin: 4px 0 0 0;
}
.homePage .homeMain .Notice .r h2:hover {
  color: rgb(51, 122, 183);
}
.homePage .homeMain .Notice .r .homeText {
  color: #717171;
  font-size: 18px;
  line-height: 28px;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 2;
  // overflow: hidden;
  // word-break: break-all;
}
.homePage .homeMain .Notice .r .swiper-pagination-news .swiper-pagination-bullet {
  width: 40px;
  height: 4px;
  border-radius: 0px;
}
.homePage .homeMain .Notice .r .swiper-pagination-news .swiper-pagination-bullet-active {
  background-color: #e70604;
}
.homePage
  .homeMain
  .Notice
  .r
  .swiper-pagination-news
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: -3px 4px;
}
// 间隔部分
.homePage .homeMain .clear {
  width: 100%;
  height: 10px;
  clear: both;
  overflow: hidden;
}

// 新闻动态部分
.homePage .homeMain .NewsL {
  width: 665px;
  overflow: hidden;
  float: left;
  overflow-x: hidden;
  position: relative;
}
.homePage .homeMain .NewsL .Tit {
  width: 100%;
  height: 53px;
  background: url(../../assets/image/newsLine.jpg) bottom repeat-x;
}
.homePage .homeMain .NewsL .Tit .en {
  width: 65px;
  font-size: 20px;
  color: #000000;
  float: right;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 14px;
}
.homePage .homeMain .NewsL .Tit .cn {
  width: 130px;
  font-size: 24px;
  color: #0162b3;
  float: right;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
}
.homePage .homeMain .NewsL .flashImg {
  // width: 665px;
  width: 100%;
  height: 410px;
  overflow: hidden !important;
  margin-top: 40px;
  position: relative;
}
.homePage .homeMain .NewsL .flashImg-container {
  position: relative;
  width: 100%;
  // width: 665px;
  height: 100%;
  overflow: hidden;
}
.homePage .homeMain .NewsL .flashImg-slide {
  height: 100%;
  width: 665px;
  overflow: hidden;
  position: relative;
}
.homePage .homeMain .NewsL .flashImg-slide .swiper_img {
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 2s ease-in;
  -webkit-transition: 2s all ease-in; /* 适用Safari 和 Chrome兼容 */
  -moz-transition: 2s all ease-in; /* 适用Firefox兼容 */
  -ms-transition: 2s all ease-in; /* 适用IE9兼容 */
  -o-transition: 2s all ease-in; /* Opera */
}
.homePage .homeMain .NewsL .flashImg-slide .swiper_img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2); /*在Safari 和 Chrome兼容下还原*/
  -moz-transform: scale(1.2); /*在Firefox兼容下还原*/
  -ms-transfrom: scale(1.2); /*在IE9兼容下还原*/
  -o-transform: scale(1.2); /*在opera兼容下还原*/
  transform: scale(1.2); /*适用于所有浏览兼容下还原*/
}

.homePage .homeMain .NewsL .flashImg .flashImg-slide .txt {
  // width: 665px;
  width: 100%;
  height: 72px;
  overflow: hidden;
  background-color: #000000;
  margin-top: -76px;
  z-index: 2;
  opacity: 0.7;
}
.homePage .homeMain .NewsL .flashImg .txt .txtTitle {
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  white-space: nowrap; /*规定段落中的文本不进行换行 */
  // width: 580px; /*需要配合宽度来使用*/
  width: 80%; /*需要配合宽度来使用*/
}

.NewsL .flashImg .txt .txtContent {
  overflow: hidden;
  line-height: 24px;
  font-size: 13px;
  color: #dadee3;
  text-indent: 24px;
  font-family: '宋体';
  margin: 0 auto;

  // 同一行超出文本使用省略号显示，设置宽度代码
  // overflow: hidden;/*超出部分隐藏*/
  // text-overflow:ellipsis;/* 超出部分显示省略号 */
  // white-space: nowrap;/*规定段落中的文本不进行换行 */
  // width: 620px;/*需要配合宽度来使用*/
}

.homePage .homeMain .NewsL .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url(../../assets/image/z.png);
  background-size: 20px 30px;
}

.homePage .homeMain .NewsL .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url(../../assets/image/y.png);
  background-size: 20px 30px;
}

//新闻列表部分
.homePage .homeMain .NewsR {
  width: 510px;
  float: right;
  overflow: hidden;
  margin-top: 12px;
}

.homePage .homeMain .NewsR .Tit {
  width: 100%;
  height: 40px;
  overflow: hidden;
}
.homePage .homeMain .NewsR .Tit a {
  width: 100px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  display: block;
  float: left;
  font-size: 16px;
  margin-right: 2px;
  background-color: #dfdfdf;
  color: #000000;
  font-weight: bolder;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
.homePage .homeMain .NewsR .Tit a:hover {
  color: #fff;
  background-color: #0162b3;
}
.homePage .homeMain .NewsR .NewsTInner.active a {
  color: #fff;
  background-color: #0162b3;
}
.homePage .homeMain .NewsR .ItemList {
  width: 100%;
  overflow: hidden;
  padding-top: 10px;
}
.homePage .homeMain .NewsR .ItemList .comUl {
  padding-left: 0px;
}
.homePage .homeMain .NewsR .ItemList .comUl li {
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin: 0 auto;
}
.homePage .homeMain .NewsR .ItemList .comUl li a {
  float: left;
  white-space: nowrap;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  color: #000000;
  font-size: 16px;
}

.homePage .homeMain .NewsR .ItemList .comUl li a:hover {
  animation: comUla 0.5s linear 1;
  color: #0162b3;
}
@keyframes comUla {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-6px);
  }
}
.comUl li span {
  float: right;
  font-size: 15px;
}

// 重要公告部分
.homePage .homeMain .importNotice {
  height: 54px;
  background: #f2f2f2;
  line-height: 54px;
  margin: 20px auto;
  width: 84%;
}

.homePage .homeMain .importNoticeImg1 {
  width: 100%;
  margin-top: 20px;
}
.homePage .homeMain .importNotice .t {
  width: 120px;
  height: 54px;
  overflow: hidden;
  float: left;
  font-size: 16px;
  color: #ff0000;
  text-align: right;
  margin-left: 10px;
  background: url(../../assets/image/importNotice.png) left center no-repeat;
  font-weight: bold;
}
.homePage .homeMain .importNotice .Gd {
  // width: 1050px;
  width: 85%;
  height: 54px;
  overflow: hidden;
  float: left;
  margin-left: 10px;
}
// .homePage .homeMain .importNotice #demoLeft1 {
//   float: left;
//   overflow: hidden;
//   width: 1040px;
// }
// .homePage .homeMain .importNotice #demoLeft1 .indemo {
//   width: 800%;
// }
// .homePage .homeMain .importNotice #demo1,
// #demo2 {
//   float: left;
// }
.importNotice .Gd a:hover {
  color: #337ab7;
}
.homePage .homeMain .importNotice .clear {
  width: 100%;
  height: 10px;
  clear: both;
  overflow: hidden;
}
// 假冒国企公告专栏
.homePage .homeMain .fakeEnterprise {
  float: right;
  width: 15%;
  height: 54px;
  background-color: red;
  background: url(../../assets/image/jmgqggzl.jpg) center no-repeat;
  background-size: cover;
  margin: 20px auto;
}

// 业务板块 甘肃建投 中国500强企业
.homePage .importYeWu {
  height: 702px;
  overflow: hidden;
  background: url(../../assets/image/YeWu.jpg) center no-repeat;
  background-size: cover;
}
.homePage .width_100 {
  width: 100% !important;
}
.homePage .text-center {
  text-align: center;
}
.homePage .importYeWu .Item {
  height: 476px;
  // overflow: hidden;
  margin-top: 7px;
}
.homePage .importYeWu .main_panel {
  width: 1200px;
  height: auto;
  // overflow: hidden;
  margin: 0 auto;
}
.homePage .importYeWu .Item .Left {
  width: 244px;
  height: 476px;
  // overflow: hidden;
  position: relative;
  z-index: 2;
  float: left;
}
.homePage .importYeWu .Item .Left ul {
  margin: 0;
  padding: 0;
}
.homePage .importYeWu .Item .Left ul li {
  width: 95%;
  height: 68px;
  // overflow: hidden;
  border-bottom: 1px #cfcfcf solid;
}
li {
  list-style: none;
}
.homePage .importYeWu .Item .Left ul li img {
  float: right;
  margin-right: -40px;
  margin-top: -49px;
}

.homePage .importYeWu .Item .Left ul li a {
  width: 258px;
  height: 68px;
  display: block;
  background-color: #3fb2e1;
  border-bottom: 1px #cfcfcf solid;
  text-align: center;
  line-height: 68px;
  font-size: 18px;
  // overflow: hidden;

  color: #ffffff;
}

.homePage .importYeWu .Item .Left ul a:hover {
  color: #fff;
  background-color: #0162b3;
}
.homePage .importYeWu .Item .Left ul a:active {
  color: #fff;
  background-color: #0162b3;
}
.homePage .importYeWu .Item .Left ul li.active a {
  color: #fff;
  background-color: #0162b3;
}
.homePage .importYeWu .Item .Left ul li img {
  display: none;
}
.homePage .importYeWu .Item .Left ul li img.active {
  display: block;
}

.homePage .importYeWu .Item .Right {
  width: 956px;
  height: 482px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-left: 258px;
}
.homePage .importYeWu .Item .Right a {
  width: 956px;
  height: 482px;
  overflow: hidden;
  display: block;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
.homePage .importYeWu .Item .Right img {
  height: 100%;
  width: 100%;
  transition: all 2s ease-in;
  -webkit-transition: 2s all ease-in; /* 适用Safari 和 Chrome兼容 */
  -moz-transition: 2s all ease-in; /* 适用Firefox兼容 */
  -ms-transition: 2s all ease-in; /* 适用IE9兼容 */
  -o-transition: 2s all ease-in; /* Opera */
}
.homePage .importYeWu .Item .Right img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2); /*在Safari 和 Chrome兼容下还原*/
  -moz-transform: scale(1.2); /*在Firefox兼容下还原*/
  -ms-transfrom: scale(1.2); /*在IE9兼容下还原*/
  -o-transform: scale(1.2); /*在opera兼容下还原*/
  transform: scale(1.2); /*适用于所有浏览兼容下还原*/
}

// 各种链接，网站集群

.homePage .Links {
  padding: 25px 0;
  margin-top: 25px;
  background: url(../../assets/image/Links.jpg) center center no-repeat;
  background-size: cover;
}
.homePage .Links .main_panel {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.homePage .Links .P1 {
  height: 186px;
  background: #ffffff url(../../assets/image/Broad.png) left center no-repeat;
  background-size: 76px;
  opacity: 0.6;
  box-sizing: border-box;
}
.homePage .Links .P1 .linkInner {
  padding: 15px 15px 15px 90px;
}
.homePage .Links .ct {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #0162b3;
  font-size: 22px;
  font-weight: bold;
  padding-top: 16px;
}
.homePage .Links p {
  line-height: 26px;
  color: #0162b3;
  margin: 0;
  font-size: 15px;
}
.homePage .Links .linkPetition p {
  line-height: 24px;
  margin-top: -5px;
}
.homePage .Links .P1 a,
a:focus,
a:hover {
  text-decoration: none;
}
.homePage .Links .P1 a {
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
  font-size: 16px;
  color: #000000;
}
.homePage .Links .P2 {
  width: 370px;
  height: 213px;
  margin-top: 20px;
  float: left;
  margin-left: 15px;
}

.homePage .Links .P2 a:hover .en {
  animation: myEn 0.5s linear 1;
}
@keyframes myEn {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-6px);
  }
}

.homePage .Links .P2 a:hover .cn {
  animation: myCn 0.5s linear 1;
}
@keyframes myCn {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(6px);
  }
}

.homePage .Links .P2 .L1 {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  opacity: 0.6;
  text-align: center;
}
.homePage .Links .P2 .cn {
  color: #0162b3;
  font-size: 24px;
  font-weight: bold;
  height: 36px;
  line-height: 36px;
}
.homePage .Links .P2 .L2 {
  width: 164px;
  height: 54px;
  float: left;
  display: block;
  background-color: #ffffff;
  opacity: 0.6;
}
.homePage .Links .P2 .L2 .cn {
  margin-left: 25px;
  height: 22px;
  line-height: 22px;
  letter-spacing: 2px;
  margin-top: 6px;
}
.homePage .Links .P2 .L2 .en {
  margin-top: 0px;
  margin-left: 25px;
  height: 30px;
  display: inline-block;
  color: #0162b3;
  font-size: 12px;
  font-weight: bold;
}

.homePage .Links .P3 {
  height: 244px;
}
.homePage .Links .P3::after {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  clear: both;
  content: '';
}
.homePage .Links .P3 .t {
  text-align: center;
  width: 53px;
  height: 100%;
  float: left;
  font-size: 22px;
  color: #0162b3;
  font-weight: bold;
  background-color: #ffffff;
  opacity: 0.6;
  line-height: 244px;
}
.homePage .Links .P3 .t .tInner {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}
.homePage .Links .P3 .t .tInner span {
  line-height: 28px;
}
.homePage .Links .P3 .en {
  height: 53px;
  position: relative;
  z-index: 2;
  transform: rotate(90deg);
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: -29px;
  margin-top: 20px;
  color: #0162b3;
}
.homePage .Links .P3 .nr {
  height: 100%;
  background-color: #ffffff;
  opacity: 0.6;
  margin-left: 54px;
  z-index: 2;
}
.homePage .Links .P3 .nr .link-container {
  margin: 2px auto 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 4px 0 10px 0;
  z-index: 1;
  width: 100%;
  height: 244px;
  box-sizing: border-box;
}

.homePage .Links .P3 .nr .link-container .swiper-slide {
  font-size: 18px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
}
.homePage .Links .P3 .nr .link-container .swiper-slide .aItem {
  width: 95%;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  display: block;
  float: none;
  margin: 0px auto 0px auto;
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  white-space: nowrap; /*规定段落中的文本不进行换行 */
}
.homePage .Links .P3 .nr .link-container .swiper-slide a:hover {
  color: #0162b3;
}

// 精品项目轮播图
.homePage .Jpgcw {
  margin-top: 30px;
  height: 603px;
  overflow: hidden;
  background: url(../../assets/image/gcbj.jpg) center no-repeat;
  background-size: cover;
}
.homePage .Jpgcw .Pcn {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #0162b3;
  font-size: 30px;
  font-weight: bold;
}
.homePage .Jpgcw .Pen {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

#Jpgc {
  height: 403px;
  overflow: hidden;
  margin-top: 35px;
  position: static;
}
.homePage .Jpgcw .main_panel {
  width: 1200px;
  height: auto;
  // overflow: hidden;
  margin: 20px auto 0 auto;
}
.homePage .Jpgcw .jpmore {
  width: 100px;
  height: 36px;
  background-color: #0162b3;
  display: block;
  color: #ffffff;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  border-radius: 3px;
}
#Jpgc .JpgcSwiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: inherit;
  list-style: none;
  padding: 0;
  z-index: 1;
}
#Jpgc .JpgcSwiper-container .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  box-sizing: content-box;
}
#Jpgc .JpgcSwiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 380px;
  margin-right: 30px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

#Jpgc .JpItem {
  width: 100%;
  height: 382px;
  background-color: #f0f4f5;
  display: block;
  overflow: hidden;
  border: 10px #dadada solid;
  box-sizing: border-box;
}

#Jpgc .JpItem .img {
  width: 100%;
  height: 242px;
  overflow: hidden;
}
#Jpgc .JpItem .img img {
  transition: all 0.5s ease-in;
  -webkit-transition: 0.5s all ease-in; /* 适用Safari 和 Chrome兼容 */
  -moz-transition: 0.5s all ease-in; /* 适用Firefox兼容 */
  -ms-transition: 0.5s all ease-in; /* 适用IE9兼容 */
  -o-transition: 0.5s all ease-in; /* Opera */
}
#Jpgc .JpItem .Tit {
  width: 100%;
  height: 35px;
  text-align: center;
  line-height: 35px;
  margin-top: 15px;
}
#Jpgc .JpItem .nr {
  width: 350px;
  padding: 15px;
  overflow: hidden;
  line-height: 26px;
  text-align: left;
  text-indent: 2em;
  font-size: 13px;
  box-sizing: border-box;
}
#Jpgc .JpItem .img {
  width: 100%;
  height: 242px;
  overflow: hidden;
}
#Jpgc .JpItem:hover .img img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2); /*在Safari 和 Chrome兼容下还原*/
  -moz-transform: scale(1.2); /*在Firefox兼容下还原*/
  -ms-transfrom: scale(1.2); /*在IE9兼容下还原*/
  -o-transform: scale(1.2); /*在opera兼容下还原*/
  transform: scale(1.2); /*适用于所有浏览兼容下还原*/
}
#Jpgc .JpItem:hover .Tit {
  color: #0162b3;
}
#Jpgc .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  top: auto;
  margin-top: -215px;
  left: auto;
  background-image: none;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  background-image: url(../../assets/image/z.png);
  margin-left: -40px;
  // right: 0;
}
#Jpgc .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  top: auto;
  margin-top: -215px;
  left: auto;
  background-image: none;
  // margin-right: -50px;
  margin-left: 1210px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  background-image: url(../../assets/image/y.png);
  list-style: none;
}
#swiperNotice .swiper-slide {
  width: auto;
}
.linkBottom {
  text-align: center;
  height: 48px;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
}
.linkBottom span {
  display: inline-block;
  margin: 0 10px;
  font-size: 22px;
  color: #237abf;
}
.linkBottom .txt {
  font-weight: bold;
}
.importNotice {
  width: 960px;
  float: left;
}
.homePage .importYeWu .main_panel::after {
  display: block;
  width: 0px;
  height: 0px;
  content: '';
  clear: both;
  overflow: hidden;
}
.importYeWuTit img {
  max-width: 80%;
}
.importNoticeImg {
  display: block;
  margin: 20px 0px;
  width: 235px;
  height: 54px;
  overflow: hidden;
  float: right;
}
.importNoticeImg img {
  width: 100%;
  height: 100%;
}
.JpgcwTitle {
  color: #0162b3;
  font-size: 30px;
  font-weight: bold;
}
.YwLeftMobile {
  display: none;
}
@media (max-width: 1300px) {
  .homePage .homeMain,
  .homePage .importYeWu .main_panel,
  .homePage .Links .main_panel,
  .homePage .Jpgcw .main_panel {
    width: 1000px;
  }
  .homePage .ziHead .swiper-container .swiper-wrapper .swiper-slide {
    // height: 454px;
    // height: auto;
  }
  .homePage .homeMain .Notice .r {
    width: 840px;
  }
  .homePage .homeMain .NewsL {
    width: 50%;
  }
  .homePage .homeMain .NewsR {
    width: 48%;
  }
  .importNotice {
    width: 754px;
  }
  .homePage .homeMain .importNotice .Gd {
    width: 604px;
  }
  .homePage .importYeWu .Item .Right {
    width: 738px;
  }
  .homePage .Links .P1 .linkInner {
    padding: 15px 15px 15px 62px;
  }
  .homePage .Links p {
    font-size: 13px;
  }
  .homePage .Links .P1 {
    background-size: 60px;
  }
  #jpgcPhoto .swiper-button-next {
    margin-left: 1011px;
  }
}
@media (max-width: 992px) {
  .homePage .homeMain,
  .homePage .importYeWu .main_panel,
  .homePage .Links .main_panel,
  .homePage .Jpgcw .main_panel {
    width: 96%;
  }
  .homePage .ziHead .swiper-container .swiper-wrapper .swiper-slide {
    // height: 326px;
    height: auto;
  }
  .homePage .homeMain {
    margin-top: 20px;
  }
  .homePage .importYeWu .Item .Left {
    width: 26%;
  }
  .homePage .importYeWu .Item .Left ul li {
    width: 100%;
  }
  .homePage .importYeWu .Item .Left ul li a {
    width: 100%;
  }
  .homePage .importYeWu .Item .Left ul li img {
    margin-right: -13px;
  }
  .homePage .importYeWu .Item .Right {
    margin-left: 0;
    float: right;
    width: 74%;
  }
  .importYeWuTit {
    height: 40px !important;
  }
  .homePage .importYeWu {
    height: auto;
    padding-bottom: 30px;
  }
  .homePage .Links .P1 .linkInner {
    padding: 10px;
  }
  .homePage .Links .ct {
    font-size: 16px;
    padding-top: 10px;
  }
  .homePage .Links .linkPetition p {
    line-height: 19px;
  }
  .linkBottom span,
  .homePage .Links .P3 .t {
    font-size: 20px;
  }
  #Jpgc {
    margin-top: 20px;
  }
  #jpgcPhoto {
    position: relative;
  }
  #Jpgc .swiper-button-next,
  #Jpgc .swiper-button-prev {
    display: none;
  }
  .homePage .homeMain .Notice .Tit {
    width: 106px;
    height: auto;
  }
  .homePage .homeMain .Notice .Tit img {
    width: 100%;
  }
  .homePage .homeMain .Notice .r {
    width: calc(100% - 126px);
  }
  .homePage .homeMain .Notice .r h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .homePage .homeMain .Notice .r .homeText {
    font-size: 14px;
    line-height: 22px;
  }
  .homePage .homeMain .NewsL .flashImg,
  .homePage .homeMain .NewsL .flashImg-container {
    width: 100%;
  }
  .homePage .homeMain .NewsL .flashImg-slide {
    width: 100% !important;
  }
  .homePage .homeMain .NewsL .flashImg .flashImg-slide .txt {
    width: 100%;
  }
  .homePage .homeMain .NewsL .flashImg .txt .txtTitle {
    width: 100%;
  }
  .NewsL .flashImg .txt .txtContent {
    line-height: 18px;
  }
  .importNoticeImg {
    width: 180px;
  }
  .importNotice {
    width: calc(100% - 190px);
  }
  .homePage .homeMain .importNotice .Gd {
    width: calc(100% - 150px);
  }
}
@media (max-width: 768px) {
  #bigPhoto .swiper-button-next,
  #bigPhoto .swiper-button-prev {
    display: none;
  }
  .homePage .ziHead .swiper-container .swiper-wrapper .swiper-slide {
    // height: 200px;
    height: auto;
  }
  .homePage .ziHead .swiper-container .swiper-wrapper .swiper-slide img {
    // height: auto !important;
    object-fit: cover;
  }
  .importYeWuTit {
    height: 28px !important;
    margin: 25px 0 !important;
  }
  .homePage .importYeWu .Item .Left {
    width: 20%;
    height: auto;
  }
  .homePage .importYeWu .Item .Right {
    width: 80%;
    height: 328px;
  }
  .homePage .importYeWu .Item .Left ul li {
    height: 46px;
  }
  .homePage .importYeWu .Item .Left ul li a {
    font-size: 14px;
    height: 100%;
    line-height: 46px;
  }
  .homePage .importYeWu .Item .Left ul li img {
    width: 10px;
    margin-right: -10px;
    margin-top: -35px;
  }
  .homePage .importYeWu .Item .Right a {
    height: 328px;
  }
  .homePage .homeMain .Notice .Tit {
    display: none;
  }
  .homePage .homeMain .Notice .r {
    width: 100%;
  }
  .homePage .homeMain .Notice .r h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .homePage .homeMain .Notice .r .homeText {
    font-size: 12px;
    line-height: 20px;
  }
  .homePage .homeMain .NewsL {
    width: 100%;
  }
  .homePage .homeMain .NewsR {
    width: 100%;
  }
  .homePage .homeMain .NewsL .Tit .en {
    float: left;
    font-size: 14px;
    width: auto;
    margin-right: 8px;
    margin-top: 11px;
  }
  .homePage .homeMain .NewsL .Tit .cn {
    float: left;
    font-size: 18px;
    width: auto;
  }
  .homePage .homeMain .NewsL .Tit {
    height: 48px;
  }
  .homePage .homeMain .NewsL .flashImg {
    margin-top: 20px;
  }
  .homePage .homeMain .clear {
    height: 0;
  }
  .homePage .homeMain .NewsL .flashImg {
    height: 238px;
  }
  .homePage .homeMain .NewsL .flashImg .txt .txtTitle {
    font-size: 14px;
    line-height: 30px;
  }
  .NewsL .flashImg .txt .txtContent {
    font-size: 12px;
    line-height: 18px;
  }
  .homePage .homeMain .NewsR .Tit {
    white-space: nowrap;
    overflow-x: auto;
  }
  .NewsTInner {
    display: inline-block;
  }
  .NewsTInner a {
    display: block;
    float: none;
  }
  .homePage .homeMain .NewsR .Tit a {
    width: 76px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
  }
  .homePage .homeMain .NewsR .ItemList .comUl li {
    height: 40px;
    line-height: 40px;
  }
  .homePage .homeMain .NewsR .ItemList {
    padding-top: 0;
  }
  .homePage .homeMain .importNotice {
    width: 100%;
    margin: 4px auto;
  }
  .importNoticeImg {
    width: 100%;
    height: auto;
    margin: 9px 0 10px;
  }
  .homePage .homeMain .importNotice .t {
    width: 26px;
  }
  .homePage .homeMain .importNotice .t span {
    display: none;
  }
  .homePage .homeMain .importNotice .Gd {
    width: calc(100% - 48px);
  }
  .homePage .Links .P1 {
    height: auto;
  }
  .homePage .Links .P1 .linkInner {
    padding-left: 62px;
  }
  .linkBottom {
    margin-bottom: 10px;
  }
  .homePage .Jpgcw {
    margin-top: 20px;
  }
  .JpgcwTitle {
    font-size: 22px;
  }
  .homePage .Jpgcw .Pen {
    font-size: 14px;
    line-height: 22px;
  }
  .homePage .Jpgcw .Pcn {
    margin-top: 10px;
  }
  #Jpgc {
    margin-top: 10px;
    height: auto;
  }
  .homePage .Jpgcw {
    height: auto;
    padding-bottom: 20px;
  }
  #Jpgc .JpItem .img {
    height: 158px !important;
  }
  #Jpgc .JpItem {
    border-width: 6px;
    height: auto;
    padding-bottom: 10px;
  }
  #Jpgc .JpItem .Tit {
    height: auto;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  #Jpgc .JpItem .nr {
    display: none;
  }
  #YwRight .Rlist {
    width: 100%;
    height: 100%;
  }
  .homePage .importYeWu .Item .Right a {
    width: 100%;
    height: 100%;
  }
  .YwLeftPc {
    display: none;
  }
  .YwLeftMobile {
    display: block;
  }
  .homePage .Jpgcw .jpmore {
    margin-top: 30px;
  }
  .homePage .homeMain .NewsR {
    margin-top: 25px;
  }
  .homePage .homeMain .NewsR .ItemList .comUl {
    margin-top: 10px;
  }
}
@media (max-width: 580px) {
  .importYeWuTit {
    height: 20px !important;
  }
}
</style>
